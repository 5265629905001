export const sortElementsByTime = (profileData) => {
  const sixHoursAgo = new Date().getTime() - 6 * 60 * 60 * 1000; // Timestamp for 6 hours ago

  // Convert the data object to an array of entries
  const sortedPhoneNumbers = Object.keys(profileData).map((key) => {
    const conversation = profileData[key];

    // Ensure conversation is an array
    if (!Array.isArray(conversation)) {
      return null; // Skip invalid entries
    }

    // Count the number of messages within the last 6 hours
    const recentMessageCount = conversation.reduce((count, message) => {
      const messageTime = new Date(message.RESPONSE_UPDATED_DATETIME).getTime();
      return messageTime >= sixHoursAgo ? count + 1 : count;
    }, 0);

    const lastMessage = conversation[conversation.length - 1];
    return {
      phoneNumber: key,
      lastMessageTime: new Date(lastMessage.RESPONSE_UPDATED_DATETIME),
      recentMessageCount, // Include this in the returned object for further use
    };
  });

  // Filter out any null entries (due to invalid conversations)
  const validPhoneNumbers = sortedPhoneNumbers.filter(
    (entry) => entry !== null
  );

  // Sort the array based on lastMessageTime
  validPhoneNumbers.sort((a, b) => {
    return b.lastMessageTime - a.lastMessageTime; // Sort in descending order
  });

  return validPhoneNumbers;
};
