import React, { useState, useRef, useContext } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { MessageBox } from "react-chat-elements"; // Importing MessageBox
import "react-chat-elements/dist/main.css"; // Import CSS for styling
import userImage from "./chatimages/userimage.svg"; // Import your user image
import {
  Search,
  MainContainer,
  ChatContainer,
  MessageList,
  Sidebar,
  Avatar,
  ConversationList,
  Conversation,
  MessageInput,
  MessageSeparator,
  ConversationHeader,
  Message,
} from "@chatscope/chat-ui-kit-react";
import { chats } from "./main";
import { sortElementsByTime } from "./sortTime";
import "./chat.css";
import IntialChatScreen from "./intialChatScreen";

export let chatPhoneNumbers = [];
export let filteredData = [];

const formatTime = (timeString) => {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const twelveHourFormatHours = hours % 12 || 12; // Convert 0 to 12
  const time = `${twelveHourFormatHours}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${amOrPm}`;

  // Format the date as DD/MM/YYYY
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  return ` ${time}`;
};

function UserProfiles() {
  const [profileData, setProfileData] = useContext(chats); // Chat data from context
  const [activeConversation, setActiveConversation] = useState(null); // Active conversation's phone number
  const [userName, setUserName] = useState(); // Active conversation's user name
  const [userMessages, setUserMessages] = useState([]); // Messages for active conversation
  const [userTime, setUserTime] = useState([]); // Timestamps for messages
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [showSearchedChat, setShowSearchedChat] = useState(false); // State to control displaying searched chat

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const todayDateString = today.toDateString();
  const yesterdayDateString = yesterday.toDateString();

  // Handle conversation click
  const handleClick = (phoneNumber, name) => {
    setActiveConversation(phoneNumber);
    setUserName(name);
    setShowSearchedChat(false);
    const gettingMessages =
      profileData[
        Object.keys(profileData).find((key) => {
          const [keyPhoneNumber] = key.split("|");
          return keyPhoneNumber.trim() === phoneNumber.trim();
        })
      ] || [];
    let messages = [];
    let messagetime = [];
    gettingMessages.forEach((x) => {
      messages.push(x.GENERAL_CHAT);
      messagetime.push(x.RESPONSE_UPDATED_DATETIME);
    });
    setUserMessages(messages);
    setUserTime(messagetime);
  };

  // Sort profile data by time
  let sortedProfileData = sortElementsByTime(profileData);

  // Handle search input
  const handleSearch = (e) => {
    let input1 = e;
    try {
      if (input1 === "") {
        setSearchQuery("");
      } else {
        setSearchQuery(input1);
        // Filter the data based on search query
        filteredData = sortedProfileData.filter((item) => {
          let phonum = item.phoneNumber;
          if (phonum.length > 10) {
            phonum = phonum.substring(2);
          }
          return phonum.startsWith(input1);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const conversationsToDisplay = searchQuery ? filteredData : sortedProfileData;
  console.log(userMessages);

  let prevDate = null;

  return (
    <div>
      <MainContainer responsive>
        <Sidebar position="left" className="whatsapp-sidebar">
          {/* Search input */}
          <Search
            onChange={handleSearch}
            placeholder="Search By Phone Numbers"
          />
          <ConversationList>
            {conversationsToDisplay.map((item, index) => {
              const [phoneNumber, name] = item.phoneNumber.split("|");
              const lastMessage =
                profileData[item.phoneNumber][
                  profileData[item.phoneNumber].length - 1
                ];

              // Format the date for the last message
              const formattedDate = formatTime(
                lastMessage.RESPONSE_UPDATED_DATETIME
              );

              return (
                <Conversation
                  key={index}
                  name={`${phoneNumber} | ${name}`}
                  info={lastMessage.GENERAL_CHAT}
                  lastActivityTime={formattedDate}
                  onClick={() => handleClick(phoneNumber, name)}
                >
                  <Avatar src={userImage} name={name} />
                </Conversation>
              );
            })}
          </ConversationList>
        </Sidebar>

        {/* Chat container */}
        <ChatContainer style={{ flex: 1, position: "relative" }}>
          <ConversationHeader className="whatsapp-header">
            <ConversationHeader.Content
              userName={activeConversation}
              info={userName}
            />
          </ConversationHeader>
          <IntialChatScreen />
          <MessageList className="whatsapp-message-list">
            {userMessages.length > 0 ? (
              userMessages.map((x, ind) => {
                let date = new Date(userTime[ind]);
                let currentDate = date.toDateString();
                if (currentDate !== prevDate) {
                  prevDate = currentDate;
                  if (currentDate === todayDateString) currentDate = "Today";
                  if (currentDate === yesterdayDateString)
                    currentDate = "Yesterday";
                  return (
                    <React.Fragment key={ind}>
                      <MessageSeparator
                        content={currentDate}
                        style={{ color: "green" }}
                      />
                      <Message.CustomContent>
                        <div className="chatMessages">
                          <span>{x}</span>
                          <span style={{ fontSize: "0.8rem", color: "gray" }}>
                            <br />
                            {formatTime(userTime[ind])}
                          </span>
                        </div>
                      </Message.CustomContent>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Message.CustomContent>
                      <div className="chatMessages">
                        <span>{x}</span>
                        <span style={{ fontSize: "0.8rem", color: "gray" }}>
                          <br />
                          {formatTime(userTime[ind])}
                        </span>
                      </div>
                    </Message.CustomContent>
                  );
                }
              })
            ) : (
              <IntialChatScreen />
            )}
          </MessageList>
          
          <MessageInput
            className="whatsapp-input"
            placeholder="Type a message..."
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}

export default UserProfiles;
