import React, { useContext, useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import userImage from "./chatimages/userimage.svg"; // Import your user image
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Sidebar,
  MessageInput,
  MessageSeparator,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  ConversationHeader,
  Message,
} from "@chatscope/chat-ui-kit-react";
import axios from "axios";
import { chats } from "./main";
import { sortElementsByTime } from "./sortTime";
import "./chat.css";
import IntialChatScreen from "./intialChatScreen";
import { persondata } from "../../Authentication/FetchedUserData";

// Utility function to format time and date
const formatTime = (timeString) => {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const twelveHourFormatHours = hours % 12 || 12;

  const time = `${twelveHourFormatHours}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${amOrPm}`;
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  // Handle "Today" and "Yesterday"
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) return `Today ${time}`;
  if (date.toDateString() === yesterday.toDateString())
    return `Yesterday ${time}`;

  return `${formattedDate} ${time}`;
};

const formatTimeOnly = (timeString) => {
  const date = new Date(timeString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const twelveHourFormatHours = hours % 12 || 12;

  return `${twelveHourFormatHours}:${
    minutes < 10 ? "0" + minutes : minutes
  } ${amOrPm}`;
};

function Chat() {
  const [profileData, setProfileData] = useContext(chats); // Chat data from context
  const [activeConversation, setActiveConversation] = useState(null); // Active conversation's phone number
  const [userName, setUserName] = useState(); // Active conversation's user name
  const [userMessages, setUserMessages] = useState([]); // Messages for active conversation
  const [userTime, setUserTime] = useState([]); // Timestamps for messages
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  // Handle conversation click
  const handleClick = (phoneNumber, name) => {
    setActiveConversation(phoneNumber);
    setUserName(name);

    const messages =
      profileData[
        Object.keys(profileData).find(
          (key) => key.split("|")[0].trim() === phoneNumber.trim()
        )
      ] || [];
    setUserMessages(messages.map((msg) => msg.GENERAL_CHAT));
    setUserTime(messages.map((msg) => msg.RESPONSE_UPDATED_DATETIME));
  };

  // Sort profile data by time
  const sortedProfileData = sortElementsByTime(profileData);
  // Handle search input
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const sendMessage = (message) => {
    const messageData = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "+" + activeConversation,
      type: "text",
      text: { preview_url: true, body: message },
    };

    axios
      .post(
        `https://graph.facebook.com/v21.0/${persondata.phoneNo_id}/messages`,
        messageData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${persondata.permanenttoken}`,
          },
        }
      )
      .then((response) => console.log(response.data))
      .catch((error) => console.log(error));
  };

  // Filter conversations based on search
  const conversationsToDisplay = searchQuery
    ? sortedProfileData.filter(
        (item) => item.phoneNumber.substring(2).startsWith(searchQuery) // removing the first 2 digits
      )
    : sortedProfileData;

  return (
    <MainContainer responsive style={{ height: "600px" }}>
      {/* Left Sidebar */}
      <Sidebar position="left">
        <Search placeholder="Search with phone" onChange={handleSearch} />
        <ConversationList>
          {conversationsToDisplay.map((item, index) => {
            const [phoneNumber, name] = item.phoneNumber.split("|");
            const lastMessage = profileData[item.phoneNumber]?.slice(-1)[0];
            const formattedDate = formatTime(
              lastMessage?.RESPONSE_UPDATED_DATETIME
            );
            return (
              <Conversation
                key={index}
                name={`${phoneNumber} | ${name}`}
                info={lastMessage?.GENERAL_CHAT}
                lastActivityTime={formattedDate}
                onClick={() => handleClick(phoneNumber, name)}
                unreadCnt={item.recentMessageCount}
              >
                <Avatar src={userImage} name={name} />
              </Conversation>
            );
          })}
        </ConversationList>
      </Sidebar>

      {/* Chat Container */}
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Content
            userName={activeConversation}
            info={userName}
          />
        </ConversationHeader>
        <MessageList>
          {userMessages.length > 0 ? (
            userMessages.map((msg, index) => (
              <React.Fragment key={index}>
                {index === 0 ||
                new Date(userTime[index]).toDateString() !==
                  new Date(userTime[index - 1]).toDateString() ? (
                  <MessageSeparator
                    content={formatTime(userTime[index]).split(" ")[0]}
                    style={{ color: "green" }}
                  />
                ) : null}
                <Message.CustomContent>
                  <div className="chatMessages">
                    <span>{msg}</span>
                    <span style={{ fontSize: "0.8rem", color: "gray" }}>
                      <br />
                      {formatTimeOnly(userTime[index])}
                    </span>
                  </div>
                </Message.CustomContent>
              </React.Fragment>
            ))
          ) : (
            <IntialChatScreen />
          )}
        </MessageList>

        {/* Conditionally render the MessageInput */}
        {userMessages.length > 0 && (
          <MessageInput placeholder="Type message here" onSend={sendMessage} />
        )}
      </ChatContainer>
    </MainContainer>
  );
}

export default Chat;
